import { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

/** Lang */
import ptBR from '@lang/pt-BR'

/** Services */
import ProductService from '@services/Product/ProductService'

/** Components */
import { Container } from '@mui/material'
import {
    Header,
    Loading,
    Footer,
    MaterialLayout,
    ProductHashNotFound,
} from '@components'

/** Modules */
import Checkout from '@modules/Checkout'

/** Implementation */
const displayName = 'App'

const propTypes = {}

const defaultProps = {}

const Component = () => {
    /** Window */
    const params = new URL(document.location).searchParams
    const productHashCode = params.get('product')

    const utmSource = params.get('utmSource')
    const utmMedium = params.get('utmMedium')
    const utmCampaign = params.get('utmCampaign')

    /** States */
    const [loading, setLoading] = useState(true)
    const [result, setResult] = useState(null)

    /** Effects */
    useEffect(() => {
        setLoading(true)

        // prettier-ignore
        ProductService
            .findProductByHashCode(productHashCode)
            .then((result) => {
                setResult(result)
                setLoading(false)
            })
    }, [productHashCode])

    /** Render */
    return (
        <IntlProvider messages={ptBR} locale={'pt-BR'} defaultLocale={'pt-BR'}>
            <MaterialLayout>
                {loading ? (
                    <Loading />
                ) : (
                    <Container maxWidth={'sm'}>
                        {result ? (
                            <>
                                <Header
                                    company={result?.company}
                                    product={{
                                        description: result?.description,
                                        name: result?.name,
                                    }}
                                />

                                <Checkout
                                    initialValues={{
                                        plan_name: result.name,
                                        first_payment_term_type: result.first_payment_term_type,
                                        first_payment_term_value: result.first_payment_term_value,
                                        payment_term_type: result.payment_term_type,
                                        payment_term_value: result.payment_term_value,
                                        product_name: result.product_name,
                                        product_hash: productHashCode,
                                        product_description: result.description,
                                        charges: result.charges,
                                        term_of_use: result?.company?.term_of_use,
                                        privacy_policy: result?.company?.privacy_policy,
                                        frequency: result.frequency,
                                        utmSource: utmSource,
                                        utmMedium: utmMedium,
                                        utmCampaign: utmCampaign
                                    }}
                                />

                                <Footer />
                            </>
                        ) : (
                            <ProductHashNotFound />
                        )}
                    </Container>
                )}
            </MaterialLayout>
        </IntlProvider>
    )
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
