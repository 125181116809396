import React from 'react'
import ReactDOMClient from 'react-dom/client'

/** Commons */
import reportWebVitals from './reportWebVitals'

/** Component */
import App from './App'

// prettier-ignore
ReactDOMClient
  .createRoot(document.getElementById('root'))
  .render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
