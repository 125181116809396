import creditCardType from 'credit-card-type'

/** Constants */
import {
    CustomerPaymentInfoConstants,
    CustomerConstants,
} from '@commons/constants'

/** Commons */
import i18n from '@commons/i18n'

/** Implementation */
const getDocument = (values) =>
    values.customer_type === CustomerConstants.TYPE_COMPANY
        ? values.company_cnpj
        : values.document

const getFullName = (values) =>
    values.customer_type === CustomerConstants.TYPE_COMPANY
        ? values.company_name
        : `${values.first_name} ${values.last_name}`

const getFullAddress = (values) =>
    `${values.address}, ${values.number}, ${values.complement} - ${
        values.neighbourhood
    }, ${values.city.label} - ${values.state_province}, ${i18n.t(
        'postal_code'
    )} ${values.postal_code}`

const getPaymentMethod = (values) => {
    switch (values.payment_method) {
        case CustomerPaymentInfoConstants.PAYMENT_MEHTOD_BOLETO:
            return i18n.t('boleto')
        case CustomerPaymentInfoConstants.PAYMENT_MEHTOD_PIX:
            return i18n.t('pix')
        case CustomerPaymentInfoConstants.PAYMENT_MEHTOD_CREDIT_CARD:
            return i18n.t('credit_card')
        default:
            return i18n.t('unknown')
    }
}

const getObfuscatedDigits = (values) =>
    `***** ${values.credit_card_info.digits.slice(-4)}`

const getCreditCardBrand = (values) => {
    switch (values.credit_card_info.brand) {
        case creditCardType.types.AMERICAN_EXPRESS:
            return i18n.t('cc_brand_american_express')
        case creditCardType.types.DINERS_CLUB:
            return i18n.t('cc_brand_diners_club')
        case creditCardType.types.DISCOVER:
            return i18n.t('cc_brand_discover')
        case creditCardType.types.ELO:
            return i18n.t('cc_brand_elo')
        case creditCardType.types.HIPER:
            return i18n.t('cc_brand_hiper')
        case creditCardType.types.JCB:
            return i18n.t('cc_brand_jcb')
        case creditCardType.types.MAESTRO:
            return i18n.t('cc_brand_maestro')
        case creditCardType.types.MASTERCARD:
            return i18n.t('cc_brand_mastercard')
        case creditCardType.types.UNIONPAY:
            return i18n.t('cc_brand_unionpay')
        case creditCardType.types.VISA:
            return i18n.t('cc_brand_visa')
        default:
            return i18n.t('unknown')
    }
}

const utils = {
    getFullName,
    getFullAddress,
    getPaymentMethod,
    getObfuscatedDigits,
    getCreditCardBrand,
}

export {
    getCreditCardBrand,
    getDocument,
    getFullAddress,
    getFullName,
    getObfuscatedDigits,
    getPaymentMethod,
}

export default utils
