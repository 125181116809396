import React from 'react'

/** Components */
import { Grid, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

/** Styles */
import { theme, useStyle } from './styles'

/** Implementation */
const displayName = 'MaterialLayout'

const propTypes = {}

const defaultProps = {}

const Component = ({ children }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <ThemeProviderWrapper>{children}</ThemeProviderWrapper>
    </ThemeProvider>
)

const ThemeProviderWrapper = ({ children }) => {
    const classes = useStyle()

    return (
        <div className={classes.root}>
            <Grid container justifyContent={'center'}>
                {children}
            </Grid>
        </div>
    )
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
