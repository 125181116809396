import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
    centeredBox: {
        textAlign: 'center',
    },

    checkCircleIcon: {
        fontSize: '10rem !important',
        left: '50%',
        position: 'relative',
        transform: 'translateX(-50%)',
        color: theme.palette.success.light,
    },

    loadingWrapper: {
        height: 200,
        left: '50%',
        position: 'relative',
        transform: 'translateX(-50%)',
        width: 127,
    },
}))
