import { AxiosAdapter } from '@commons'

export default class CouponService {
    static verifyCoupon(product_hash, coupon) {
        if (!coupon) {
            return new Promise((res, rej) =>
                rej({
                    message: 'Cupom: Campo do cupom está vazio'
                })
            )
        }

        return AxiosAdapter.get(`/products/${product_hash}/coupon/${coupon}`)
    }
}