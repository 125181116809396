import PropTypes from 'prop-types'
import i18n from '@commons/i18n'

/** Components */
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    Typography,
} from '@mui/material'

/** Implementation */
const displayName = 'Header'

const propTypes = {
    company: PropTypes.shape({
        company_logo: PropTypes.string,
        name: PropTypes.string,
        registration: PropTypes.string,
    }),
    product: PropTypes.shape({
        description: PropTypes.string,
        name: PropTypes.string,
    }),
}

const defaultProps = {}

const Component = ({ company, product }) => (
    <>
        {/*
        |-------------------------------------------------------------------
        | Company description
        |-------------------------------------------------------------------
        */}

        <List
            sx={{
                mt: (theme) => theme.spacing(3),
            }}
        >
            <ListItem
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ListItemAvatar>
                    <Avatar
                        alt={'Company Logo'}
                        src={company?.company_logo}
                        sx={{ height: 64, width: 64, mr: 2 }}
                    />
                </ListItemAvatar>

                <ListItemText
                    sx={{
                        flex: 'unset',
                    }}
                    primary={company.name}
                    secondary={
                        <Typography
                            sx={{ display: 'inline' }}
                            component={'span'}
                            variant={'body2'}
                            color={'text.primary'}
                        >
                            {company.registration}
                        </Typography>
                    }
                />
            </ListItem>
        </List>

        <Divider />

        {/*
        |-------------------------------------------------------------------
        | Product description
        |-------------------------------------------------------------------
        */}

        <Box>
            <List>
                {/* <ListItem sx={{ textAlign: 'center' }}>
                    <Typography
                        color={'text.primary'}
                        sx={{ width: '100%' }}
                        variant={'h4'}
                    >
                        {company?.email}
                    </Typography>
                </ListItem> */}

                {company?.email && (
                    <ListItem>
                        <Typography
                            sx={{ fontSize: 14, width: '100%' }}
                            color={'text.secondary'}
                        >
                            {i18n.t('header_description')} {company?.email}
                        </Typography>
                    </ListItem>
                )}
            </List>
        </Box>
    </>
)

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
