import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import { Button, CircularProgress, Divider, Grid, Typography } from '@mui/material'

import { useFormikContext } from 'formik'

import CouponService from '@services/Coupon/CouponService'

import i18n from '@commons/i18n'

import { FieldInput } from '@components'

import { calcCharges } from './utils'
import checkoutUtils from '../../utils'

import useStyles from '../../styles'

const displayName = 'OrderCart'

const propTypes = {
    plan_name: PropTypes.string,
    product_name: PropTypes.string,
    product_description: PropTypes.string,
    charges: PropTypes.array
}

const Component = ({ plan_name, product_name, product_description, charges }) => {
    const classes = useStyles()

    const formik = useFormikContext()

    const [couponLoading, setCouponLoading] = useState(false)

    const handleVerifyCoupon = async () => {
        setCouponLoading(true)
        CouponService.verifyCoupon(formik.values.product_hash, formik.values.coupon_code).then(response => {
            if (response && response.status === 'INACTIVE') {
                throw new Error('Coupon is not active');
            }

            formik.setFieldValue('coupon', response)

            const discount = response.type === 'FIXED' ? response.value : (formik.values.subtotal * response.value)
            formik.setFieldValue('discount', { type: response.type, value: response.value, recurrence: response.recurrence, result: discount })
            setCouponLoading(false)
        }).catch(() => {
            formik.setFieldValue('discount', {
                type: '',
                value: 0,
                recurrence: 0,
                result: 0
            })
            formik.setFieldError('coupon_code', 'coupon_is_not_valid')
            setCouponLoading(false)
        });
    }

    // initialize form values
    useEffect(() => {
        if (!formik.values.fixed_charge)
            formik.setFieldValue('fixed_charge', charges.reduce((sum, charge) => {
                if (charge.uom === 'FIXED' && charge.recurrence === 0)
                    sum += charge.value

                return sum
            }, 0))

        if (!formik.values.setup_charge)
            formik.setFieldValue('setup_charge', charges.reduce((sum, charge) => {
                if (charge.uom === 'FIXED' && charge.recurrence > 0)
                    sum += charge.value

                return sum
            }, 0))

        const chargesByUom = charges.reduce((groups, charge) => {
            if (charge.uom !== 'FIXED') {
                if (groups[charge.uom])
                    groups[charge.uom].push(charge)
                else
                    groups[charge.uom] = [charge]
            }
            return groups
        }, {})

        if (!formik.values.chargesByUom)
            formik.setFieldValue('charges_by_uom', chargesByUom)

        if (formik.values.usage_count.length === 0) {
            formik.setFieldValue('usage_count', [])
            Object.keys(chargesByUom).forEach((uom, index) => {
                formik.setFieldValue(`usage_count_subtotals.${index}`, 0)
                formik.setFieldValue(`usage_count.${index}`, { uom, value: 0, quantity: 0 })
            })
        }
    }, [])

    // useEffect to update everything on usage_count change
    useEffect(() => {
        // calc subtotal
        const { sum, subtotals } = calcCharges(charges, formik.values.usage_count)
        if (formik.values.usage_count.length > 0)
            formik.setFieldValue('usage_count_subtotals', subtotals)

        const newSubtotal = sum + formik.values.fixed_charge
        formik.setFieldValue('subtotal', newSubtotal)

        // calc discount
        let discount = 0
        if (formik.values.discount.type) {
            discount = formik.values.discount.type === 'FIXED' ? formik.values.discount.value : (newSubtotal * formik.values.discount.value)
            formik.setFieldValue('discount', { ...formik.values.discount, result: discount })
        }

        let setup = formik.values.setup_charge

        // calc total
        const total = newSubtotal + setup - discount
        if (total < 0)
            formik.setFieldValue('total', 0)
        else
            formik.setFieldValue('total', checkoutUtils.round(total))
    }, [formik.values.usage_count])

    // useEffect to calc discount and total on discount change
    useEffect(() => {
        // calc discount
        let discount = 0
        if (formik.values.discount.type) {
            discount = formik.values.discount.type === 'FIXED' ? formik.values.discount.value : ((formik.values.subtotal + formik.values.setup_charge) * formik.values.discount.value)
            formik.setFieldValue('discount.result', discount)
        }

        // calc total
        const total = formik.values.subtotal + formik.values.setup_charge - discount
        if (total < 0)
            formik.setFieldValue('total', 0)
        else
            formik.setFieldValue('total', checkoutUtils.round(total))
    }, [formik.values.discount.value])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant={'h6'} gutterBottom>
                    {i18n.t('order_cart')}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Divider />
            </Grid>

            {charges.length > 0 &&
                <>
                    <Grid item xs={12} sm={12}>
                        <Typography fontWeight={600} color={'#686969'}>
                            {product_name} - {plan_name}
                        </Typography>

                        <div className={classes.fixedValue}>
                            <Typography fontWeight={600} fontSize={'1.1rem'}>
                                {formik.values.fixed_charge !== 0 && <>
                                    <FormattedNumber
                                        currency='BRL'
                                        style='currency'
                                        value={formik.values.fixed_charge}
                                    />
                                    {Object.keys(formik.values.charges_by_uom).length > 0 && <> + {' '}</>}
                                </>}
                                {Object.keys(formik.values.charges_by_uom).map(uom => {
                                    return uom
                                }).join(' + ')}
                            </Typography>
                        </div>

                        {product_description &&
                            <Typography variant='body2'>
                                {product_description}
                            </Typography>
                        }
                    </Grid>

                    {formik.values.usage_count.length !== 0 &&
                        <div className={classes.usageCountContainer}>
                            {formik.values.usage_count.map((usage, index) => {
                                return (
                                    <div key={index} className={classes.usageCount}>
                                        <Typography className={classes.usageFieldLabel}>{usage.uom}</Typography>
                                        <FieldInput
                                            name={`usage_count.${index}.quantity`}
                                            InputProps={{ type: 'number', size: 'small', className: classes.usageFieldInput, defaultValue: '0' }}
                                        />
                                        <span name={`usage_count_subtotals.${index}`} className={classes.usageFieldDisabledInput}>
                                            R$ {formik.values.usage_count_subtotals[index]}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    }

                    <Grid item xs={12} sm={12}>
                        <Typography variant='h6'>{i18n.t('add_coupon_question')}</Typography>

                        <div className={classes.couponContainer}>
                            <FieldInput
                                name='coupon_code'
                                InputProps={{
                                    size: 'small',
                                    placeholder: i18n.t('coupon_code')
                                }}
                            />

                            <Button variant='contained' disabled={couponLoading} onClick={handleVerifyCoupon}>
                                {i18n.t('apply')}
                            </Button>

                            {couponLoading &&
                                <CircularProgress />
                            }
                        </div>
                    </Grid>
                </>
            }

        </Grid>
    )
}


Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = {}

export default Component