/** Commons */
import AxiosAdapter from '@commons/AxiosAdapter'

/** Implementation */
export default class ProductService {
    /**
     * Find product by hash code.
     * @param {*} values
     * @param {*} actions
     * @returns Promise
     */
    static findProductByHashCode(productHashCode) {
        if (productHashCode) {
            return AxiosAdapter.get(`/products/${productHashCode}`)
        }

        return new Promise((resolve) => resolve(null))
    }
}
