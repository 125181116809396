import * as Yup from 'yup'
import validateCC from 'card-validator'
import { validateCNPJ, validateCep, validateCPF } from 'validations-br'

/** Commons */
import {
    CustomerConstants,
    CheckoutConstants,
    CustomerPaymentInfoConstants,
} from '@commons/constants'

/** Contract */
import utils from './utils'

/** Implementation */
export default Yup.object().shape({
    customer_type: Yup.string()
        .oneOf([CustomerConstants.TYPE_PERSON, CustomerConstants.TYPE_COMPANY])
        .required('field_required'),

    work_email: Yup.string().when('active_step', {
        is: CheckoutConstants.STEP_REGISTER_CUSTOMER,
        then: Yup.string()
            .email('work_email_is_not_valid')
            .required('field_required'),
    }),

    work_phone: Yup.string().nullable(),

    /*------------------------------------------------------------------
    | Customer as person
    |-----------------------------------------------------------------*/

    first_name: Yup.string().when('customer_type', {
        is: (active_step, customer_type) => active_step === CheckoutConstants.STEP_REGISTER_CUSTOMER && customer_type === CustomerConstants.TYPE_PERSON,
        then: Yup.string()
            .min(2, 'too_short')
            .max(50, 'too_long')
            .required('field_required'),
    }),

    last_name: Yup.string().when('customer_type', {
        is: (active_step, customer_type) => active_step === CheckoutConstants.STEP_REGISTER_CUSTOMER && customer_type === CustomerConstants.TYPE_PERSON,
        then: Yup.string()
            .min(2, 'too_short')
            .max(50, 'too_long')
            .required('field_required'),
    }),

    document: Yup.string().when('customer_type', {
        is: (active_step, customer_type) => active_step === CheckoutConstants.STEP_REGISTER_CUSTOMER && customer_type === CustomerConstants.TYPE_PERSON,
        then: Yup.string().test(
            'document',
            'document_is_not_valid',
            validateCPF
        ),
    }),

    /*------------------------------------------------------------------
    | Customer as company
    |-----------------------------------------------------------------*/

    company_cnpj: Yup.string().when(['active_step', 'customer_type'], {
        is: (active_step, customer_type) => active_step === CheckoutConstants.STEP_REGISTER_CUSTOMER && customer_type === CustomerConstants.TYPE_COMPANY,
        then: Yup.string().test(
            'company_cnpj',
            'cnpj_is_not_valid',
            validateCNPJ
        ),
    }),

    company_name: Yup.string().when(['active_step', 'customer_type'], {
        is: (active_step, customer_type) => active_step === CheckoutConstants.STEP_REGISTER_CUSTOMER && customer_type === CustomerConstants.TYPE_COMPANY,
        then: Yup.string()
            .min(2, 'too_short')
            .max(50, 'too_long')
            .required('field_required'),
    }),

    company_razao_social: Yup.string().when(['active_step', 'customer_type'], {
        is: (active_step, customer_type) => active_step === CheckoutConstants.STEP_REGISTER_CUSTOMER && customer_type === CustomerConstants.TYPE_COMPANY,
        then: Yup.string()
            .min(2, 'too_short')
            .max(50, 'too_long')
            .required('field_required'),
    }),

    company_inscricao_estadual: Yup.string(),

    company_optante_simples_nacional: Yup.bool(),

    /*------------------------------------------------------------------
    | Address
    |-----------------------------------------------------------------*/

    postal_code: Yup.string().when('active_step', {
        is: CheckoutConstants.STEP_REGISTER_CUSTOMER,
        then: Yup.string().test(
            'postal_code',
            'postal_code_is_not_valid',
            validateCep
        ),
    }),

    state_province: Yup.string().when('active_step', {
        is: CheckoutConstants.STEP_REGISTER_CUSTOMER,
        then: Yup.string().test(
            'state_province',
            'state_province_is_empty',
            (stateProvince) => stateProvince !== undefined
        ),
    }),

    city: Yup.object().when('active_step', {
        is: CheckoutConstants.STEP_REGISTER_CUSTOMER,
        then: Yup.object().shape({ label: Yup.string(), value: Yup.string().nullable() })
            .test('city', 'city_is_empty', (city) => city?.value !== null),
    }),

    neighbourhood: Yup.string(),

    address: Yup.string().when('active_step', {
        is: CheckoutConstants.STEP_REGISTER_CUSTOMER,
        then: Yup.string().required('field_required'),
    }),

    number: Yup.string().when('active_step', {
        is: CheckoutConstants.STEP_REGISTER_CUSTOMER,
        then: Yup.string().required('field_required'),
    }),

    complement: Yup.string(),

    /*------------------------------------------------------------------
    | Payment
    |-----------------------------------------------------------------*/

    amount: Yup.number(),

    payment_method: Yup.string().oneOf([
        CustomerPaymentInfoConstants.PAYMENT_MEHTOD_LIST,
        'payment_method_is_not_valid'
    ]),

    // Should be in payment form and payment method MUST BE credit card
    // in order to validate credit card info.
    // prettier-ignore
    credit_card_info: Yup.object().when('active_step', {
        is: (activeStep) => activeStep === CheckoutConstants.STEP_PAYMENT_FORM,
        then: Yup.object().when('payment_method', {
            is: (value) => value === CustomerPaymentInfoConstants.PAYMENT_MEHTOD_CREDIT_CARD,
            then: Yup.object().shape({
                digits: Yup.string().test(
                    'digits',
                    'credit_card_number_is_not_valid',
                    (value) => validateCC.number(value).isValid
                ),

                holder_name: Yup.string().test(
                    'holder_name',
                    'field_required',
                    (value) => validateCC.cardholderName(value).isValid
                ),

                brand: Yup.string().nullable(),

                expiration_date: Yup.string().test(
                    'expiration_date',
                    'expiration_date_is_not_valid',
                    utils.validationSchema.creditCardExpirationDate
                ),

                security_code: Yup.string().test(
                    'security_code',
                    'field_required',
                    (value) => value && value.length > 2 && value.length < 5
                ),
            }),
        }),
    }),
})
