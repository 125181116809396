import { useField } from 'formik'
import creditCardType from 'credit-card-type'

/** Components */
import { InputAdornment } from '@mui/material'
import FieldInput from '@components/FieldInput'

import {
    Amex,
    DinersClub,
    Discover,
    Elo,
    Generic,
    Hiper,
    Jcb,
    Maestro,
    Mastercard,
    UnionPay,
    Visa,
} from 'react-pay-icons'

/** Implementation */
const displayName = 'FieldCreditCardNumber'

const propTypes = {}

const defaultProps = {}

const Component = ({ label, name }) => {
    /** Field */
    const [field] = useField(name)

    /** Render */
    return (
        <FieldInput
            label={label}
            name={name}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position={'end'}>
                        <BrandIcon digits={field.value} />
                    </InputAdornment>
                ),
            }}
            maskProps={{
                mask: '0000 0000 0000 0000 0000',
            }}
        />
    )
}

// prettier-ignore
const BrandIcon = ({ digits }) => {
    const style = {
        borderRadius: 6,
        margin: 4,
        marginTop: 6,
        width: 48,
    }

    const type = getCreditCardType(digits)?.type

    if (type === creditCardType.types.AMERICAN_EXPRESS) return <Amex style={style} />
    if (type === creditCardType.types.DINERS_CLUB) return <DinersClub style={style} />
    if (type === creditCardType.types.DISCOVER) return <Discover style={style} />
    if (type === creditCardType.types.ELO) return <Elo style={style} />
    if (type === creditCardType.types.HIPER) return <Hiper style={style} />
    if (type === creditCardType.types.JCB) return <Jcb style={style} />
    if (type === creditCardType.types.MAESTRO) return <Maestro style={style} />
    if (type === creditCardType.types.MASTERCARD) return <Mastercard style={style} />
    if (type === creditCardType.types.UNIONPAY) return <UnionPay style={style} />
    if (type === creditCardType.types.VISA) return <Visa style={style} />

    return <Generic style={style} />
}

const getCreditCardType = (digits) => {
    if (digits) {
        const cards = creditCardType(digits)
        return cards.length > 0 ? cards[0] : null
    }

    return null
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
