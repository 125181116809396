import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useField } from 'formik'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import { IMaskInput } from 'react-imask'
import { TextField } from '@mui/material'

/** Implementation */
const displayName = 'FieldInput'

const propTypes = {
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    maskProps: PropTypes.shape({
        definitions: PropTypes.object,
        mask: PropTypes.string,
    }),
    name: PropTypes.string,
}

const defaultProps = {
    InputProps: null,
    maskProps: null,
}

const Component = ({
    disabled,
    fullWidth,
    InputProps,
    label,
    maskProps,
    name,
    required,
}) => {
    const [field, meta] = useField(name)

    const helperText = getHelperText(meta)

    if (maskProps) {
        InputProps = {
            ...InputProps,
            inputProps: maskProps,
            inputComponent: MaskTextField,
        }
    }

    return (
        <TextField
            disabled={disabled}
            error={meta.touched && meta.error && true}
            fullWidth={fullWidth}
            helperText={helperText}
            InputProps={InputProps}
            label={getLabel(label, required)}
            name={name}
            type={'text'}
            variant={'outlined'}
            {...field}
        />
    )
}

const getLabel = (label, isRequired) =>
    isRequired ? i18n.t(label) + '*' : i18n.t(label)

const getHelperText = (meta) =>
    meta?.touched && meta?.error ? i18n.t(meta?.error) : ''

const MaskTextField = forwardRef(
    ({ definitions, mask, onChange, name, ...props }, ref) => (
        <IMaskInput
            {...props}
            definitions={definitions}
            inputRef={ref}
            mask={mask}
            onAccept={(value) => onChange({ target: { name, value } })}
            overwrite={true}
        />
    )
)

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
