import { Fragment } from 'react'

import { FormattedNumber } from 'react-intl'

/** Constants */
import CustomerPaymentInfoConstants from '@commons/constants/CustomerPaymentInfoConstants'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import { Divider, Grid, Typography } from '@mui/material'

/** Contract */
import {
    getCreditCardBrand,
    getDocument,
    getFullAddress,
    getFullName,
    getObfuscatedDigits,
    getPaymentMethod,
} from './utils'

import useStyles from '../../styles'

/** Implementation */
const displayName = 'ReviewOrder'

const propTypes = {}

const defaultProps = {}

const Component = ({ values }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant={'h6'} gutterBottom>
                    {i18n.t('review_order')}
                </Typography>

                <Divider />
            </Grid>

            <Grid container item spacing={3}>
                <Grid item xs={12} sm={12}>

                    <Typography fontWeight={600} color={'#686969'}>
                        {values.product_name} - {values.plan_name}
                    </Typography>

                    <div className={classes.fixedValue}>
                        <Typography fontWeight={600} fontSize={'1.1rem'}>
                            {values.fixed_charge !== 0 && <>
                                <FormattedNumber
                                    currency='BRL'
                                    style='currency'
                                    value={values.fixed_charge}
                                />
                                {Object.keys(values.charges_by_uom).length > 0 && <> + {' '}</>}
                            </>}
                            {Object.keys(values.charges_by_uom).map(uom => {
                                return uom
                            }).join(' + ')}
                        </Typography>
                    </div>

                    {values.product_description &&
                        <Typography variant='body2'>
                            {values.product_description}
                        </Typography>
                    }

                    <Typography sx={{ display: 'none' }}>
                        <FormattedNumber
                            currency={'BRL'}
                            style={'currency'}
                            value={values.amount}
                        />
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography gutterBottom variant={'h6'}>
                        {i18n.t('customer')}
                    </Typography>
                    <Typography gutterBottom>{getFullName(values)}</Typography>
                    <Typography gutterBottom>{getDocument(values)}</Typography>
                    <Typography gutterBottom>{values.work_email}</Typography>
                    <Typography gutterBottom>{values.work_phone}</Typography>
                    <Typography gutterBottom>{getFullAddress(values)}</Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography gutterBottom variant={'h6'}>
                        {i18n.t('payment_form')}
                    </Typography>

                    <Typography gutterBottom>{getPaymentMethod(values)}</Typography>

                    {values.payment_method ===
                        CustomerPaymentInfoConstants.PAYMENT_MEHTOD_CREDIT_CARD && (
                            <Grid container item>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>
                                        {i18n.t('number')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>
                                        {getObfuscatedDigits(values)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>
                                        {i18n.t('credit_card_brand')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>
                                        {getCreditCardBrand(values)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>
                                        {i18n.t('short_holder_name')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>
                                        {values.credit_card_info.holder_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography gutterBottom>
                                        {i18n.t('short_expiration_date')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>
                                        {values.credit_card_info.expiration_date}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography gutterBottom variant={'h6'}>
                        {i18n.t('discount')}
                    </Typography>

                    {values.discount.result > 0 && values.discount.recurrence === 0 && <>
                        <Typography gutterBottom>
                            {i18n.t('discount_review')}
                            <FormattedNumber
                                currency='BRL'
                                style='currency'
                                value={values.discount.result}
                            />
                        </Typography>
                    </>}

                    {values.discount.result > 0 && values.discount.recurrence === 1 && <>
                        <Typography gutterBottom>
                            {i18n.t('discount_review') } <FormattedNumber
                                currency='BRL'
                                style='currency'
                                value={values.discount.result}
                            /> { i18n.t('discount_review_single')}
                        </Typography>
                    </>}

                    {values.discount.result > 0 && values.discount.recurrence > 1 && <>
                        <Typography gutterBottom>
                            {i18n.t('discount_review')} <FormattedNumber
                                currency='BRL'
                                style='currency'
                                value={values.discount.result}
                            /> {i18n.t('discount_review_multiple')} {values.discount.recurrence} {i18n.t('invoices')}
                        </Typography>
                    </>}
                    <Typography gutterBottom></Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography gutterBottom variant={'h6'}>
                        {i18n.t('payment_term')}
                    </Typography>

                    {values.first_payment_term_type && <>
                        {values.first_payment_term_type === 'FIXED' ? (
                            <Typography gutterBottom>
                                1ª Cobrança: Vencimento dia {values.first_payment_term_value}
                            </Typography>
                        ) : (
                            <Typography gutterBottom>
                                1ª Cobrança: Vencimento {values.first_payment_term_value} dias após a emissão
                            </Typography>
                        )}
                    </>}

                    {values.payment_term_type && <>
                        {values.payment_term_type === 'FIXED' ? (
                            <Typography gutterBottom>
                                Cobranças futuras: Vencimento dia {values.payment_term_value}
                            </Typography>
                        ) : (
                            <Typography gutterBottom>
                                Cobranças futuras: Vencimento {values.payment_term_value} dias após a emissão
                            </Typography>
                        )}
                    </>}
                    <Typography gutterBottom></Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
