import { AddressConstants } from 'commons/constants'

const getCpf = (cpf) => (cpf === '' ? null : cpf)

// prettier-ignore
const getStateAbbreviation = (stateProvince) =>
    AddressConstants
        .BRL_STATE_PROVINCE_LIST
        .find(({ state }) => state === stateProvince)
        ?.shortName ?? null

const getWorkPhone = (workPhone = '') => {
    if (workPhone === '') return ''

    const [ddd, number] = workPhone.split(' ')
    const numberOnly = number.replace(/(-)/, '')
    const endPart = numberOnly.substring(numberOnly.length - 4)
    const initialPart = numberOnly.substring(0, numberOnly.length - 4)

    return `${ddd} ${initialPart}-${endPart}`
}

const CustomerUtils = { getCpf, getStateAbbreviation, getWorkPhone }

export default CustomerUtils

export { getCpf, getStateAbbreviation, getWorkPhone }
