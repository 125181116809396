import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { blue } from '@mui/material/colors'

let theme = createTheme({
    palette: {
        primary: blue,
        secondary: blue,
        background: {
            default: '#f8fafc',
        },
    },
})

theme = responsiveFontSizes(theme)

const useStyle = makeStyles(() => ({
    root: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 'auto',
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
}))

export { theme, useStyle }
