/** Commons */
import AxiosAdapter from '@commons/AxiosAdapter'
import { parseCreditCardInfoToPayload } from './OrderUtils'

/** Implementation */

/**
 * Order Service
 */
export default class OrderService {
    static placeOrder(
        productHash,
        customerHash,
        paymentMethod,
        creditCardInfo,
        usage_count,
        coupon,
        utmSource,
        utmMedium,
        utmCampaign
    ) {
        usage_count = usage_count.map((usage) => {
            return {
                uom: usage.uom,
                value: usage.value,
                quantity: usage.quantity !== '' ? usage.quantity : 0,
            }
        })

        const payload = {
            hash: productHash,
            customer: {
                hash: customerHash,
            },
            payment: {
                payment_method_type: paymentMethod,
                ...parseCreditCardInfoToPayload(creditCardInfo),
            },
            usage_count,
            coupon: {
                hash: coupon.hash
            },
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign
        }

        return AxiosAdapter.post('/orders', payload)
    }

    static getOrder(orderHash) {
        return AxiosAdapter.get(`/orders/${orderHash}`)
    }
}
