import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(3),
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    buttonProgress: {
        position: 'absolute',
        top: '20%',
        left: '40%',
    },
    couponContainer: {
        display: 'flex',
        gap: '20px',
        margin: '20px 0',
        alignItems: 'flex-start'
    },
    fixedValue: {
        marginTop: '10px',
    },
    paper: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    subtotalUom: {
        display: 'inline-block',
        maxWidth: '200px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineHeight: '100%',
        verticalAlign: 'text-bottom',
    },
    termOfUse: {
        justifyContent: 'flex-start',
    },
    totalContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingTop: '24px',
        textAlign: 'end'
    },
    usageCount: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px'
    },
    usageCountContainer: {
        paddingLeft: '24px',
        marginTop: '40px',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    usageFieldInput: {
        width: '100px'
    },
    usageFieldDisabledInput: {
        color: '#686969'
    },
    couponRecurrenceNotice: {
        color: '#686969'
    },
    usageFieldLabel: {
        maxWidth: '200px',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    wrapper: {
        margin: theme.spacing(0),
        marginLeft: theme.spacing(1),
        position: 'relative',
    },
}))
