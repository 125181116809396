/** Constants */
import {
    AddressConstants,
    CustomerConstants,
    ErrorConstants,
} from '@commons/constants'

/** Commons */
import AxiosAdapter from '@commons/AxiosAdapter'

/** Utils */
import {
    getStateAbbreviation,
    getWorkPhone,
    getCpf,
} from '@services/Customer/CustomerUtils'

/** Implementation */

/**
 * Customer Service
 */
export default class CustomerService {
    /**
     * Create customer
     * @param {*} values
     * @param {*} actions
     * @returns Promise
     */
    static createCustomer(values) {
        // Validating data
        if (values.city.value === null) {
            return new Promise((resolve, reject) =>
                reject({
                    name: ErrorConstants.ERROR_CITY_NOT_SELECTED,
                    message: 'Cidade: Cidade não foi selecionada',
                })
            )
        }

        // Customer as a person
        let payload = {
            product_hash: values.product_hash,

            // Customer
            first_name: values.first_name,
            last_name: values.last_name,
            name: `${values.first_name} ${values.last_name}`,
            document: values.document,
            cpf: getCpf(values.document),
            work_email: values.work_email,
            work_phone: getWorkPhone(values.work_phone),

            // Address
            country: AddressConstants.COUNTRY_BRAZIL,
            postal_code: values.postal_code,
            state_province: values.state_province,
            state_abbreviation: getStateAbbreviation(values.state_province),
            city: values.city.label,
            ibge_code: values.city.value,
            neighbourhood: values.neighbourhood,
            address: values.address,
            number: values.number,
            complement: values.complement,
            captcha: values.captcha
        }

        // Company
        // prettier-ignore
        if (values?.customer_type === CustomerConstants.TYPE_COMPANY) {
            payload = {
                ...payload,
                cnpj: values.company_cnpj,
                company_name: values.company_name, // Nome Fantasia
                document: values.company_cnpj,
                inscricao_estadual: values.company_inscricao_estadual,
                name: values.company_name, // Nome Fantasia
                optante_simples_nacional: Boolean(values.company_optante_simples_nacional),
                razao_social: values.company_razao_social, // Razão social
            }
        }

        return AxiosAdapter.post('/customers', payload)
    }
}
