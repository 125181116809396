const PAYMENT_MEHTOD_BOLETO = 'BOLETO'
const PAYMENT_MEHTOD_PIX = 'PIX'
const PAYMENT_MEHTOD_CREDIT_CARD = 'CREDIT_CARD'

const PAYMENT_MEHTOD_LIST = [
    PAYMENT_MEHTOD_BOLETO,
    PAYMENT_MEHTOD_PIX,
    PAYMENT_MEHTOD_CREDIT_CARD,
]

const CustomerPaymentInfoConstants = {
    PAYMENT_MEHTOD_BOLETO,
    PAYMENT_MEHTOD_PIX,
    PAYMENT_MEHTOD_CREDIT_CARD,
    PAYMENT_MEHTOD_LIST,
}

export default CustomerPaymentInfoConstants

export {
    PAYMENT_MEHTOD_BOLETO,
    PAYMENT_MEHTOD_PIX,
    PAYMENT_MEHTOD_CREDIT_CARD,
    PAYMENT_MEHTOD_LIST,
}
