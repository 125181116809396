import { createIntl, createIntlCache } from 'react-intl'

/** Lang */
import ptBR from '@lang/pt-BR'

/** Implementation */
const I18n = () => {
    const intl = createIntl(
        {
            locale: 'pt-BR',
            messages: ptBR,
        },
        // This is optional but highly recommended
        // since it prevents memory leak
        createIntlCache()
    )

    return {
        t: makeFormatMessage(intl),
    }
}

const makeFormatMessage = (intlInstance) => (context, values) => {
    if (!context) return ''

    if (typeof context === 'string')
        return intlInstance.formatMessage({ id: context }, values)

    return intlInstance.formatMessage(context, values)
}

export default I18n()
