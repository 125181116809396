import { useField } from 'formik'
import {
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@mui/material'

/** Commons */
import i18n from '@commons/i18n'

const displayName = 'FieldRadioGroupCustomerType'

const propTypes = {}

const defaultProps = {}

const Component = () => {
    const [field] = useField('customer_type')

    return (
        <FormControl>
            <FormLabel id='demo-radio-buttons-group-label'>
                {i18n.t('customer_type')}
            </FormLabel>
            <RadioGroup
                aria-labelledby={'radio-buttons-group-label'}
                defaultValue={'person'}
                name={'customer_type'}
                value={field.value}
                onChange={field.onChange}
            >
                <FormControlLabel
                    value={'company'}
                    control={<Radio />}
                    label={i18n.t('customer_is_company')}
                />
                <FormControlLabel
                    value={'person'}
                    control={<Radio />}
                    label={i18n.t('customer_type_person')}
                />
            </RadioGroup>
        </FormControl>
    )
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
