/** Components */
import { Grid, Typography } from '@mui/material'
import { FieldCurrency, FieldPaymentMethod } from '@components'

/** Commons */
import i18n from '@commons/i18n'

/** Implementation */
const displayName = 'FormPayment'

const propTypes = {}

const defaultProps = {}

const Component = ({ formProps }) => (
    <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
            <Typography variant={'h6'} gutterBottom>
                {i18n.t('payment_form')}
            </Typography>
        </Grid>

        <Grid container item spacing={3}>
            <Grid item xs={12} sm={12} sx={{ display: 'none' }}>
                <FieldCurrency
                    name={'amount'}
                    label={'amount'}
                    fullWidth
                    disabled
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <Grid item xs={12} sm={12}>
                    <FieldPaymentMethod
                        name={'payment_method'}
                        label={'payment_method'}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
