/** Components */
import FieldCreditCardNumber from './components/FieldCreditCardNumber'
import FieldInput from '@components/FieldInput'
import Grid from '@mui/material/Grid'

/** Implementation */
const displayName = 'FormCreditCardInfo'

const propTypes = {}

const defaultProps = { name: 'credit_card_info' }

const Component = ({ name }) => (
    <Grid container>
        <Grid container item spacing={3}>
            <Grid item xs={12} sm={12}>
                <FieldCreditCardNumber
                    label={'digits'}
                    name={`${name}.digits`}
                />
            </Grid>

            <Grid item xs={12} sm={12}>
                <FieldInput
                    label={'holder_name'}
                    name={`${name}.holder_name`}
                    fullWidth
                />
            </Grid>

            <Grid item xs={6} sm={6}>
                <FieldInput
                    label={'expiration_date'}
                    name={`${name}.expiration_date`}
                    fullWidth
                    maskProps={{
                        definitions: { '#': /[0-9]/ },
                        mask: '## / ##',
                    }}
                />
            </Grid>

            <Grid item xs={6} sm={6}>
                <FieldInput
                    label={'security_code'}
                    name={`${name}.security_code`}
                    fullWidth
                    maskProps={{
                        mask: '0000',
                    }}
                />
            </Grid>
        </Grid>
    </Grid>
)

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
