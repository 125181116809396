import PropTypes from 'prop-types'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import ErrorIcon from '@mui/icons-material/Error'
import { Box, Button, Typography } from '@mui/material'

/** Implementation */
const displayName = 'CheckoutFailed'

const propTypes = {
    apiErrorReponse: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

const defaultProps = {
    apiError: {
        detail: '',
    },
}

const Component = ({ apiErrorReponse, onDismiss }) => (
    <Box>
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant={'h6'} gutterBottom>
                {i18n.t('order_failed')}
            </Typography>

            <Typography>{i18n.t('order_failed_description')}</Typography>
        </Box>

        <Box sx={{ fontSize: '10rem', height: 180 }}>
            <ErrorIcon
                sx={{
                    fontSize: 'inherit',
                    left: '50%',
                    position: 'relative',
                    transform: 'translateX(-50%)',
                    color: (theme) => theme.palette.error.light,
                }}
            />
        </Box>

        <Box sx={{ textAlign: 'center' }}>
            {Array.isArray(apiErrorReponse) ? (
                apiErrorReponse.map(({ detail }) => (
                    <Typography gutterBottom>{detail}</Typography>
                ))
            ) : (
                <Typography gutterBottom>{apiErrorReponse?.message}</Typography>
            )}

            <Button onClick={onDismiss}>{i18n.t('back_to_form')}</Button>
        </Box>
    </Box>
)

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
