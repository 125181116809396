const calcCharges = (charges, usageCount) => {
    let sum = 0;
    let subtotals = new Array(usageCount.length).fill(0)

    for (const charge of charges) {
        if (charge.uom === 'FIXED')
            continue;

        let value = 0;
        let uomIndex = -1;

        switch (charge.type) {

            case 'FLAT':
                uomIndex = usageCount.findIndex(usage => usage.uom === charge.uom)
                if (uomIndex === -1)
                    break

                value = flatValue(charge, usageCount[uomIndex]?.quantity || 0)
                sum += value;
                subtotals[uomIndex] += value;
                break

            case 'VOLUME':
                uomIndex = usageCount.findIndex(usage => usage.uom === charge.uom)
                if (uomIndex === -1)
                    break

                value = volumeValue(charge, usageCount[uomIndex]?.quantity || 0)
                sum += value;
                subtotals[uomIndex] += value;
                break

            case 'TIERED':
                uomIndex = usageCount.findIndex(usage => usage.uom === charge.uom)
                if (uomIndex === -1)
                    break

                value = tieredValue(charge, usageCount[uomIndex]?.quantity || 0)
                sum += value;
                subtotals[uomIndex] += value;
                break

            default:
                break;
        }

        if (usageCount[uomIndex]?.quantity < 0) {
            usageCount[uomIndex].quantity = 0
        }
    }

    return { sum, subtotals }
}

const flatValue = (charge, amount = 0) => {
    const lowerBound = charge.lower_bound ? charge.lower_bound : -1;
    const upperBound = charge.upper_bound ? charge.upper_bound : Infinity;

    if (amount > lowerBound && amount <= upperBound && amount !== 0) {
        return charge.value
    }

    return 0
}

const volumeValue = (charge, amount = 0) => {
    const lowerBound = charge.lower_bound ? charge.lower_bound : -1;
    const upperBound = charge.upper_bound ? charge.upper_bound : Infinity;

    if (amount > lowerBound && amount <= upperBound && amount !== 0) {
        return amount * charge.value
    }

    return 0
}

const tieredValue = (charge, amount = 0) => {
    const lowerBound = charge.lower_bound ? charge.lower_bound : 0;
    const upperBound = charge.upper_bound ? charge.upper_bound : Infinity;

    if (amount === 0)
        return 0

    if (amount < lowerBound)
        return 0

    if (amount > upperBound)
        return (upperBound - lowerBound) * charge.value

    if (amount > lowerBound && amount <= upperBound)
        return (amount - lowerBound) * charge.value

    return 0
}

export { calcCharges }