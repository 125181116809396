import PropTypes from 'prop-types'
import { useEffect, useState, Fragment } from 'react'
import { FormattedNumber } from 'react-intl'
import { Formik, Form } from 'formik'

/** Services */
import CustomerService from '@services/Customer/CustomerService'
import OrderService from '@services/Order/OrderService'
import { orderIsAccepted } from '@services/Order/OrderUtils'

/** Constants */
import { CheckoutConstants } from 'commons/constants'

/** Commons */
import i18n from '@commons/i18n'

/** Componens */
import {
    Button,
    CircularProgress,
    Paper,
    Alert,
    Step,
    StepLabel,
    Stepper,
    Checkbox,
    Grid,
    Typography
} from '@mui/material'
import {
    CheckoutFailed,
    FormPayment,
    FormRegisterCustomer,
    OrderCart,
    WaitProcessingOrder,
    ReviewOrder,
} from './components'

/** Style */
import useStyles from './styles'

/** Contract */
import contract from './contract'
import utils from './utils'
import validationSchema from './validationSchema'

/** Implementation */
const displayName = 'Checkout'

const propTypes = {
    initialValues: PropTypes.object,
}

const defaultProps = {}

const Component = ({ initialValues }) => {
    /** Styles */
    const classes = useStyles()

    /** Aliases */
    const steps = ['order_cart', 'customer_details', 'payment_form', 'review_order']

    const combinedInitialValues = {
        ...contract.defaultInitialValues,
        ...initialValues,
    }

    /** States */
    // prettier-ignore
    const [activeStep, setActiveStep] = useState(CheckoutConstants.INITIAL_STEP)
    const [processingOrder, setProcessingOrder] = useState(null)
    const [apiErrorResponse, setApiErrorResponse] = useState(null)
    const [acceptedTerms, setAcceptedTerms] = useState(false)
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false)
    const [frequency, setFrequency] = useState(null)

    /** Actions */
    const handleBack = () => {
        if (activeStep !== CheckoutConstants.INITIAL_STEP) {
            return setActiveStep(activeStep - 1)
        }
    }

    const handleError = () => {
        setActiveStep(CheckoutConstants.CHECKOUT_FAILED)
        setApiErrorResponse(null) // Save error message
        setProcessingOrder(null)
    }

    const onSubmit = (values, actions) => {
        actions.setSubmitting(true) // Disabling form temporarily

        // Define order cart before proceed
        if (activeStep === CheckoutConstants.STEP_CART_ORDER) {
            setApiErrorResponse(null)
            setActiveStep(CheckoutConstants.STEP_REGISTER_CUSTOMER)

            actions.setSubmitting(false)
            actions.setTouched({})

            document
                .querySelector('.MuiContainer-root')
                .scrollIntoView(true)

            return null
        }

        // Create customer before proceed
        if (activeStep === CheckoutConstants.STEP_REGISTER_CUSTOMER) {
            return CustomerService.createCustomer(values)
                .then((customer) => {
                    setApiErrorResponse(null)
                    setActiveStep(CheckoutConstants.STEP_PAYMENT_FORM)

                    actions.setFieldValue('customer_hash', customer.hash)
                    actions.setSubmitting(false)
                    actions.setTouched({})

                    document
                        .querySelector('.MuiContainer-root')
                        .scrollIntoView(true)
                })
                .catch((error) => {
                    document
                        .querySelector('.MuiContainer-root')
                        .scrollIntoView(true)

                    // Default error
                    if (error === undefined) {
                        return setApiErrorResponse({
                            type: null,
                            detail: 'Erro 500: Não foi possivel cadastrar o cliente. Verifique se todos os campos foram preenchidos e tente novamente.',
                        })
                    }

                    return setApiErrorResponse({
                        type: error.name,
                        detail: error.message,
                    })
                })
        }

        // Configure payment method values
        if (activeStep === CheckoutConstants.STEP_PAYMENT_FORM) {
            setActiveStep(CheckoutConstants.STEP_REVIEW_ORDER)
            actions.setSubmitting(false)
            actions.setTouched({})

            document.querySelector('.MuiContainer-root').scrollIntoView(true)

            return null
        }

        // Create order and wait for response
        if (activeStep === CheckoutConstants.STEP_REVIEW_ORDER) {
            // prettier-ignore
            return OrderService.placeOrder(
                values.product_hash,
                values.customer_hash,
                values.payment_method,
                values.credit_card_info,
                values.usage_count,
                values.coupon,
                combinedInitialValues.utmSource,
                combinedInitialValues.utmMedium,
                combinedInitialValues.utmCampaign
            ).then((order) => {
                // Order is processing!
                // We should wait until is finished
                // and the payment details are available.
                if (orderIsAccepted(order)) {
                    setActiveStep(CheckoutConstants.STEP_PROCESSING_ORDER)
                    setProcessingOrder({ hash: order?.hash, paymentMethod: values.payment_method })
                    utils.addOrderDataIntoHref(order, values.payment_method)

                    // Order failed!
                    // Save error message and change to error page
                } else {
                    setActiveStep(CheckoutConstants.CHECKOUT_FAILED)
                    setProcessingOrder({ hash: order?.hash, paymentMethod: values.payment_method })
                    setApiErrorResponse(order)
                }

                // Mark form as touched and scroll to top of page
                actions.setSubmitting(false)
                actions.setTouched({})

                document
                    .querySelector('.MuiContainer-root')
                    .scrollIntoView(true)
            })
        }

        actions.setSubmitting(false) // Enable form submission
    }

    /** Effects */

    // Effect: when main checkout renders, we check if there are any data
    //   stored in url as search params. If we have order hash and payment
    //   method in search params, then we know some order has been processed
    //   and we should advance to STEP_PROCESSING_ORDER and load WaitProcessingOrder
    //   screen.
    useEffect(() => {
        const url = new URL(window.location.href),
            orderHash = url.searchParams.get('order_hash'),
            paymentMethod = url.searchParams.get('payment_method')

        if (orderHash) {
            setActiveStep(CheckoutConstants.STEP_PROCESSING_ORDER)
            setProcessingOrder({
                hash: orderHash,
                paymentMethod: paymentMethod,
            })
        }

        setAcceptedTerms(combinedInitialValues.term_of_use ? false : true)
        setAcceptedPrivacyPolicy(combinedInitialValues.privacy_policy ? false : true)

        let freq = null

        if (!combinedInitialValues.plan_name.includes('(cobrança única)')) {
            switch (combinedInitialValues.frequency) {
                case 1: freq = ' / mensal'; break;
                case 2: freq = ' / bimestral'; break;
                case 3: freq = ' / trimestral'; break;
                case 4: freq = ' / quadrimestral'; break;
                case 6: freq = ' / semestral'; break;
                case 12: freq = ' / anual'; break;
                default: freq = ' a cada ' + combinedInitialValues.frequency + ' meses';
            }
            setFrequency(freq)
        }

    }, [])

    /** Render */
    return (
        <Paper className={classes.paper}>
            <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{i18n.t(label)}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Formik
                initialValues={combinedInitialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({
                    errors,
                    isSubmitting,
                    setFieldValue,
                    submitCount,
                    values,
                }) => (
                    <Form>
                        {/*-----------------------------------------------------
                        | Handling Errors
                        |----------------------------------------------------*/}

                        {/* Form errors */}
                        {submitCount > 1 && Object.keys(errors).length > 0 && (
                            <Alert severity={'error'} sx={{ mb: 2 }}>
                                {i18n.t('alert_errors')}
                                <ul>
                                    {Object.entries(errors).map(
                                        ([fieldName, errorMessage]) => (
                                            <li key={fieldName}>
                                                {i18n.t(fieldName)}:{' '}
                                                {utils.getErrorMessages(
                                                    errorMessage
                                                )}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </Alert>
                        )}

                        {/* API errors */}
                        {apiErrorResponse && (
                            <Alert severity={'error'} sx={{ mb: 2 }}>
                                {i18n.t('alert_api_errors')}
                                <ul>
                                    {[]
                                        .concat(apiErrorResponse)
                                        .map(({ type, detail }, i) => (
                                            <li key={`${type}-${i}`}>
                                                {detail}
                                            </li>
                                        ))}
                                </ul>
                            </Alert>
                        )}

                        {/*-----------------------------------------------------
                        | Form pages
                        |----------------------------------------------------*/}

                        {/* Form Observer */}
                        <FormObserver
                            activeStep={activeStep}
                            setFieldValue={setFieldValue}
                            values={values}
                        />

                        {/* Step 1: Cart */}
                        {activeStep ===
                            CheckoutConstants.STEP_CART_ORDER && (
                                <OrderCart
                                    plan_name={initialValues.plan_name}
                                    product_name={initialValues.product_name}
                                    product_description={initialValues.product_description}
                                    charges={initialValues.charges}
                                />
                            )}

                        {/* Step 2: Register customer */}
                        {activeStep ===
                            CheckoutConstants.STEP_REGISTER_CUSTOMER && (
                                <FormRegisterCustomer />
                            )}

                        {/* Step 3: Choose payment form */}
                        {activeStep === CheckoutConstants.STEP_PAYMENT_FORM && (
                            <FormPayment />
                        )}

                        {/* Step 4: Review Order */}
                        {activeStep === CheckoutConstants.STEP_REVIEW_ORDER && (
                            <ReviewOrder values={values} />
                        )}

                        {/** Step 5: Order is Pending */}
                        {activeStep ===
                            CheckoutConstants.STEP_PROCESSING_ORDER && (
                                <WaitProcessingOrder
                                    processingOrder={processingOrder}
                                    onError={handleError}
                                />
                            )}

                        {/* Step 5.b: Checkout Failed */}
                        {activeStep === CheckoutConstants.CHECKOUT_FAILED && (
                            <CheckoutFailed
                                apiErrorReponse={apiErrorResponse}
                                onDismiss={() =>
                                    setActiveStep(
                                        CheckoutConstants.STEP_REGISTER_CUSTOMER
                                    )
                                }
                            />
                        )}

                        {/*-----------------------------------------------------
                        | Form Actions
                        |----------------------------------------------------*/}

                        {combinedInitialValues.term_of_use &&
                            activeStep === CheckoutConstants.LAST_STEP &&
                            (<div className={classes.termOfUse}>
                                <Checkbox
                                    checked={acceptedTerms}
                                    onChange={() => { setAcceptedTerms(!acceptedTerms) }} />
                                {i18n.t('read_and_accept')} <a href={combinedInitialValues.term_of_use} target='_blank' rel='noreferrer'>{i18n.t('terms_of_use')} </a>
                            </div>)}

                        {combinedInitialValues.privacy_policy &&
                            activeStep === CheckoutConstants.LAST_STEP &&
                            (<div className={classes.termOfUse}>
                                <Checkbox
                                    checked={acceptedPrivacyPolicy}
                                    onChange={() => { setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy) }} />
                                {i18n.t('read_and_accept')} <a href={combinedInitialValues.privacy_policy} target='_blank' rel='noreferrer'>{i18n.t('privacy_policy')} </a>
                            </div>)}

                        {(activeStep === CheckoutConstants.STEP_CART_ORDER || activeStep === CheckoutConstants.STEP_REVIEW_ORDER) &&
                            <Grid item xs={12} flexDirection='column' className={classes.totalContainer}>
                                <Typography fontWeight='bold'>
                                    {i18n.t('subtotal')} (
                                    {values.fixed_charge !== 0 && <>
                                        <FormattedNumber
                                            currency='BRL'
                                            style='currency'
                                            value={values.fixed_charge}
                                        />
                                        {Object.keys(values.charges_by_uom).length > 0 && <> + {' '}</>}
                                    </>}
                                    {Object.keys(values.charges_by_uom).length !== 0 && Object.keys(values.charges_by_uom).map((uom, index) => {
                                        return (
                                            <Fragment key={index}>
                                                {values.usage_count[index]?.quantity ? values.usage_count[index].quantity : 0} <span key={index} className={classes.subtotalUom}>{uom}</span>
                                            </Fragment>
                                        )
                                    }).reduce((prev, cur) => [prev, ' + ', cur])}

                                    ): <FormattedNumber
                                        currency='BRL'
                                        style='currency'
                                        value={utils.round(values.subtotal)}
                                    />

                                    {values.setup_charge > 0 ? frequency : ''}
                                </Typography>

                                {values.setup_charge > 0 && <Typography fontWeight='bold'>
                                    {i18n.t('setup')}: <FormattedNumber
                                        currency='BRL'
                                        style='currency'
                                        value={values.setup_charge}
                                    />

                                    {` / pontual`}
                                </Typography>}

                                {values.discount.result !== 0 && 
                                    <>
                                    <Typography fontWeight='bold'>
                                        {i18n.t('discount')}: <FormattedNumber
                                            currency='BRL'
                                            style='currency'
                                            value={utils.round(values.discount.result)}
                                        />
                                    </Typography>
                                    {
                                        values.discount.recurrence === 1 &&
                                        <Typography className={classes.couponRecurrenceNotice} variant='caption'>
                                            {i18n.t('discount_recurrence_single')}
                                        </Typography>
                                    }
                                    {
                                        values.discount.recurrence > 1 &&
                                        <Typography className={classes.couponRecurrenceNotice} variant='caption'>
                                            {i18n.t('discount_recurrence_multiple')} {values.discount.recurrence} {i18n.t('invoices')}
                                        </Typography>
                                    }
                                    </>
                                }

                                <Typography fontWeight='bold'>
                                    {i18n.t('total')}: <FormattedNumber
                                        currency='BRL'
                                        style='currency'
                                        value={values.total}
                                    />

                                    {values.setup_charge === 0 ? frequency : ''}
                                </Typography>
                            </Grid>
                        }

                        {activeStep <
                            CheckoutConstants.STEP_PROCESSING_ORDER && (
                                <div className={classes.buttons}>
                                    {activeStep >
                                        CheckoutConstants.INITIAL_STEP && (
                                            <Button
                                                onClick={handleBack}
                                                className={classes.button}
                                            >
                                                {i18n.t('back')}
                                            </Button>
                                        )}

                                    <div className={classes.wrapper}>
                                        <Button
                                            className={classes.button}
                                            color={'primary'}
                                            disabled={isSubmitting ||
                                                ((!acceptedTerms || !acceptedPrivacyPolicy) && activeStep >= CheckoutConstants.LAST_STEP) ||
                                                (!values.captcha && activeStep === CheckoutConstants.STEP_REGISTER_CUSTOMER)
                                            }
                                            type={'submit'}
                                            variant={'contained'}
                                        >
                                            {activeStep <
                                                CheckoutConstants.LAST_STEP
                                                ? i18n.t('next')
                                                : i18n.t('place_order')}
                                        </Button>

                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                    </Form>
                )}
            </Formik>
        </Paper>
    )
}

const FormObserver = ({ activeStep, setFieldValue, values }) => {
    // Effect: update active step in formik whenever form changes.
    useEffect(() => {
        setFieldValue('active_step', activeStep)
    }, [activeStep])

    return <></>
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
