import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import ReCAPTCHA from "react-google-recaptcha-enterprise";

/** Constants */
import { AddressConstants, BrlCitiesConstants } from '@commons/constants'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import { Divider, Grid, Typography } from '@mui/material'

import {
    FieldAutocomplete,
    FieldCheckbox,
    FieldInput,
    FieldSelect,
} from '@components'

import { FieldRadioGroupCustomerType } from './components'
import { CustomerConstants } from 'commons/constants'

/** Implementation */
const displayName = 'FormRegisterCustomer'

const propTypes = {}

const defaultProps = {}

const Component = () => {
    /** Form */
    const formik = useFormikContext()

    /** Variables */
    const filteredCities = filterCitiesByStateProvince(
        formik.values.state_province
    )

    /** Effects */
    // Effect: Clear city whenever state province changes
    useEffect(() => {
        formik.setFieldValue('city', { label: '', value: null })
    }, [formik.values.state_province])

    // Effect: reset form fields when changing customer typ
    useEffect(() => {
        // Reset person data when customer type becomes company
        if (formik.values.customer_type === CustomerConstants.TYPE_COMPANY) {
            formik.setFieldValue('first_name', '')
            formik.setFieldValue('last_name', '')
            formik.setFieldValue('document', '')
            formik.setFieldValue('work_email', '')
            formik.setFieldValue('work_phone', '')
            return
        }

        // Reset company data when customer type becomes person
        if (formik.values.customer_type === CustomerConstants.TYPE_PERSON) {
            formik.setFieldValue('company_cnpj', '')
            formik.setFieldValue('company_name', '')
            formik.setFieldValue('company_razao_social', '')
            formik.setFieldValue('company_inscricao_estadual', '')
            formik.setFieldValue('company_optante_simples_nacional', '')
            return
        }
    }, [formik.values.customer_type])

    return (
        <Grid container spacing={3}>
            {/*-----------------------------------------------------------------
            | Customer Info
            |----------------------------------------------------------------*/}

            <Grid item xs={12} sm={12}>
                <Typography variant={'h6'} gutterBottom>
                    {i18n.t('customer_details')}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Divider />
            </Grid>

            <Grid item xs={12} sm={12}>
                <FieldRadioGroupCustomerType />
            </Grid>

            <Grid item xs={12} sm={12}>
                <Divider />
            </Grid>

            <Grid container item spacing={3}>
                {/*-----------------------------------------------------
                | Registering as person
                |----------------------------------------------------*/}

                {formik.values.customer_type ===
                    CustomerConstants.TYPE_PERSON && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <FieldInput
                                    name={'first_name'}
                                    label={'first_name'}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FieldInput
                                    name={'last_name'}
                                    label={'last_name'}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FieldInput
                                    name={'document'}
                                    label={'document'}
                                    maskProps={{ mask: '000.000.000-00' }}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FieldInput
                                    name={'work_email'}
                                    label={'work_email'}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FieldInput
                                    name={'work_phone'}
                                    label={'work_phone'}
                                    maskProps={{ mask: '(00) 00000-0000' }}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}

                {/*-----------------------------------------------------
                | Registering as company
                |----------------------------------------------------*/}

                {formik.values.customer_type ===
                    CustomerConstants.TYPE_COMPANY && (
                        <>
                            <Grid item xs={12} sm={12}>
                                <FieldInput
                                    name={'company_cnpj'}
                                    label={'company_cnpj'}
                                    maskProps={{ mask: '00.000.000/0000-00' }}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                {/* Nome fantasia */}
                                <FieldInput
                                    name={'company_name'}
                                    label={'company_name'}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                {/* Razão social */}
                                <FieldInput
                                    name={'company_razao_social'}
                                    label={'razao_social'}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FieldInput
                                    name={'work_email'}
                                    label={'work_email'}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FieldInput
                                    name={'work_phone'}
                                    label={'work_phone'}
                                    maskProps={{ mask: '(00) 00000-0000' }}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FieldInput
                                    name={'company_inscricao_estadual'}
                                    label={'company_inscricao_estadual'}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FieldCheckbox
                                    name={'company_optante_simples_nacional'}
                                    label={'company_optante_simples_nacional'}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}

                <Grid item xs={12} sm={12}>
                    <Divider />
                </Grid>
            </Grid>

            {/*-----------------------------------------------------------------
            | Address Info
            |----------------------------------------------------------------*/}

            <Grid item xs={12} sm={12}>
                <Typography variant={'h6'} gutterBottom>
                    {i18n.t('address_info')}
                </Typography>
            </Grid>

            <Grid container item spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FieldInput
                        name={'postal_code'}
                        label={'postal_code'}
                        maskProps={{ mask: '00000-000' }}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FieldSelect
                        name={'state_province'}
                        label={'state_province'}
                        fullWidth
                        required
                        options={
                            AddressConstants.BRL_STATE_PROVINCE_LIST_OPTIONS
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FieldAutocomplete
                        label={'city'}
                        name={'city'}
                        disabled={!formik.values.state_province}
                        options={filteredCities}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FieldInput
                        name={'neighbourhood'}
                        label={'neighbourhood'}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={9}>
                    <FieldInput name={'address'} label={'address'} fullWidth />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <FieldInput name={'number'} label={'number'} fullWidth />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <FieldInput
                        name={'complement'}
                        label={'complement'}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={3}>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                    onChange={(value) => { formik.setFieldValue('captcha', value) }}
                />
            </Grid>
        </Grid>
    )
}

// prettier-ignore
const filterCitiesByStateProvince = (stateProvince) => {

    // Get state's short name code
    const uf = AddressConstants
        .BRL_STATE_PROVINCE_LIST
        .find(({ state }) => state === stateProvince)
        ?.shortName

    // Filter cities by short name
    return BrlCitiesConstants
        .ALL_IBGE_CITIES
        .filter((city) => city.uf === uf)
        .map((city) => ({
            label: `${city.name} - ${city.uf}`,
            value: city.code,
        }))
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
