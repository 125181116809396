import validateCC from 'card-validator'

/** Commons */
import i18n from '@commons/i18n'

/** Implementation */
const addOrderDataIntoHref = (order, paymentMethod) => {
    const url = new URL(window.location.href)

    url.searchParams.set('order_hash', order?.hash)
    url.searchParams.set('payment_method', paymentMethod)

    return window.history.pushState({}, null, url.href)
}

const getErrorMessages = (errors) => {
    if (typeof errors === 'string') return i18n.t(errors)

    if (typeof errors === 'object')
        return (
            <ul>
                {Object.entries(errors).map(([fieldName, errorMessage]) => (
                    <li key={fieldName}>
                        {i18n.t(fieldName)}: {i18n.t(errorMessage)}
                    </li>
                ))}
            </ul>
        )

    return ''
}

const validationSchema = {
    creditCardExpirationDate: (value) => {
        if (value) {
            const [month, year] = value.split(' / ')
            return (
                validateCC.expirationMonth(month).isValid &&
                validateCC.expirationYear(year).isValid
            )
        }
        return false
    },
}

const round = (number) => -Math.round(-number * 100) / 100

const checkoutUtils = {
    addOrderDataIntoHref,
    getErrorMessages,
    round,
    validationSchema,
}

export default checkoutUtils
