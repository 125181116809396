/** Commons */
import i18n from '@commons/i18n'

/** Implementation */
const BRL_STATE_PROVINCE_LIST = [
    { shortName: 'AC', state: 'Acre' },
    { shortName: 'AL', state: 'Alagoas' },
    { shortName: 'AM', state: 'Amazonas' },
    { shortName: 'AP', state: 'Amapá' },
    { shortName: 'BA', state: 'Bahia' },
    { shortName: 'CE', state: 'Ceará' },
    { shortName: 'DF', state: 'Distrito Federal' },
    { shortName: 'ES', state: 'Espírito Santo' },
    { shortName: 'GO', state: 'Goiás' },
    { shortName: 'MA', state: 'Maranhão' },
    { shortName: 'MG', state: 'Minas Gerais' },
    { shortName: 'MS', state: 'Mato Grosso do Sul' },
    { shortName: 'MT', state: 'Mato Grosso' },
    { shortName: 'PA', state: 'Pará' },
    { shortName: 'PB', state: 'Paraíba' },
    { shortName: 'PE', state: 'Pernambuco' },
    { shortName: 'PI', state: 'Piauí' },
    { shortName: 'PR', state: 'Paraná' },
    { shortName: 'RJ', state: 'Rio de Janeiro' },
    { shortName: 'RN', state: 'Rio Grande do Norte' },
    { shortName: 'RO', state: 'Rondônia' },
    { shortName: 'RR', state: 'Roraima' },
    { shortName: 'RS', state: 'Rio Grande do Sul' },
    { shortName: 'SC', state: 'Santa Catarina' },
    { shortName: 'SE', state: 'Sergipe' },
    { shortName: 'SP', state: 'São Paulo' },
    { shortName: 'TO', state: 'Tocantins' },
]

// prettier-ignore
const BRL_STATE_PROVINCE_LIST_OPTIONS = BRL_STATE_PROVINCE_LIST
    .map((province) => ({ label: province.state, value: province.state, }))
    .concat({ label: i18n.t('select_state_province'), value: '', })

const AddressConstants = {
    BRL_STATE_PROVINCE_LIST,
    BRL_STATE_PROVINCE_LIST_OPTIONS,
    COUNTRY_BRAZIL: 'Brasil',
}

export default AddressConstants
