import { useField } from 'formik'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'

/** Implementation */
const displayName = 'FieldCheckbox'

const propTypes = {}

const defaultProps = {}

const Component = ({ label, name }) => {
    const [field] = useField(name)

    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox {...field} />}
                label={i18n.t(label)}
            />
        </FormGroup>
    )
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
