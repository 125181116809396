import { OrderConstants } from '@commons/constants'

const orderIsAccepted = (order) =>
    [OrderConstants.STATUS_COMPLETED, OrderConstants.STATUS_RECEIVED].includes(
        order.status
    )

const parseCreditCardInfoToPayload = (creditCardInfo) => {
    const [expiration_month, expiration_year] =
        creditCardInfo.expiration_date.split(' / ')

    return {
        holder_name: creditCardInfo.holder_name,
        digits: creditCardInfo.digits.replaceAll(' ', ''),
        brand: creditCardInfo.brand,
        expiration_month,
        expiration_year,
        security_code: creditCardInfo.security_code,
    }
}

const OrderUtils = {
    parseCreditCardInfoToPayload,
    orderIsAccepted,
}

export { parseCreditCardInfoToPayload, orderIsAccepted }

export default OrderUtils
