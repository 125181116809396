import PropTypes from 'prop-types'

/** Components */
import Box from '@mui/material/Box'
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress'

/** Constants */
const CIRCULAR_PROGRESS_HEIGHT = 125 // px
const CIRCULAR_PROGRESS_THICKNESS = 1.25
const CIRCULAR_PROGRESS_SPEED = 1200 // ms

/** Implementation */
const displayName = 'Loading'

const propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Circular spinning speed given in miliseconds. */
    spinningSpeed: PropTypes.number,
}

const defaultProps = {
    height: '100vh',
    spinningSpeed: CIRCULAR_PROGRESS_SPEED,
}

const Component = ({ height, spinningSpeed }) => (
    <div style={{ height }}>
        <Box
            sx={{
                height: CIRCULAR_PROGRESS_HEIGHT,
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)',
            }}
        >
            <CircularProgress
                size={CIRCULAR_PROGRESS_HEIGHT}
                thickness={CIRCULAR_PROGRESS_THICKNESS}
                value={100}
                variant={'determinate'}
                sx={{
                    // prettier-ignore
                    color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                }}
            />
            <CircularProgress
                variant={'indeterminate'}
                disableShrink={true}
                size={CIRCULAR_PROGRESS_HEIGHT}
                thickness={CIRCULAR_PROGRESS_THICKNESS}
                // prettier-ignore
                sx={{
                    [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round', },
                    animationDuration: `${spinningSpeed}ms`,
                    color: (theme) => theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                    left: 0,
                    position: 'absolute',
                }}
            />
        </Box>
    </div>
)

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
