import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

/** Services */
import OrderService from '@services/Order/OrderService'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import QRCode from 'react-qr-code'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Button, Typography } from '@mui/material'
import { Loading } from '@components'
import { CustomerPaymentInfoConstants } from 'commons/constants'

/** Contracts */
import useStyles from './useStyles'

/** Implementation */
const displayName = 'WaitProcessingOrder'

const propTypes = {
    processingOrder: PropTypes.shape({
        hash: PropTypes.string,
        paymentMethod: PropTypes.oneOf(
            CustomerPaymentInfoConstants.PAYMENT_METHOD_TYPE_LIST
        ),
    }).isRequired,
}

const defaultProps = {}

const Component = ({ processingOrder, onError }) => {
    /** Styles */
    const classes = useStyles()

    /** Refs */
    const intervalRef = useRef(null)

    /** States */
    const [message, setMessage] = useState(null)
    const [order, setOrder] = useState(null)
    const [processing, setProcessing] = useState(true)

    /** Actions */
    const getOrder = (hash) =>
        OrderService.getOrder(hash).then((response) => {
            if (response?.status === 'ERROR') {
                onError()
                return
            }

            // Payment description is available, then the order is complete
            if (response?.payment_description) {
                setProcessing(false)
                setOrder(response)
                return
            }

            // Schedule new call
            setTimeout(() => {
                getOrder(hash)
            }, 2500)
        })

    /** Effects */
    // Effect: display a random message in order to give to the user a sense of progress
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setMessage(getRandomMessage())
        }, 3000)

        return () => {
            clearInterval(intervalRef.current)
        }
    }, [])

    // Effect: when hash is provided by main Checkout page, then asks API
    //   the document status in order to display to the user.
    useEffect(() => {
        if (processingOrder?.hash) {
            getOrder(processingOrder?.hash)
        }
    }, [processingOrder?.hash])

    /** Render */
    return (
        <Box>
            {/*-----------------------------------------------------
            | Order is processing
            |----------------------------------------------------*/}

            {processing && (
                <Box className={classes.centeredBox}>
                    <Typography variant={'h6'} gutterBottom>
                        {i18n.t('we_are_processing_your_order')}
                    </Typography>

                    <Typography>
                        {i18n.t('please_wait_until_finishes')}
                    </Typography>

                    <Box>
                        <div className={classes.loadingWrapper}>
                            <Loading height={200} spinningSpeed={3500} />
                        </div>
                    </Box>

                    <Box>
                        <Typography>{i18n.t(message)}</Typography>
                    </Box>
                </Box>
            )}

            {/*-----------------------------------------------------
            | Order is complete! Payment is available
            |----------------------------------------------------*/}

            {!processing && (
                <Box>
                    <Box className={classes.centeredBox}>
                        <Typography variant={'h6'} gutterBottom>
                            {i18n.t('order_is_processed')}
                        </Typography>
                    </Box>

                    {isBoleto(processingOrder) && (
                        <>
                            <Box>
                                <CheckCircleIcon
                                    className={classes.checkCircleIcon}
                                />
                            </Box>

                            <Box className={classes.centeredBox}>
                                <Typography gutterBottom>
                                    {i18n.t('boleto_is_available')}
                                    <br />
                                    {i18n.t('click_here_to_open_boleto')}
                                </Typography>

                                <Button
                                    variant={'contained'}
                                    href={order.payment_description}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                >
                                    {i18n.t('open_boleto')}
                                </Button>
                            </Box>
                        </>
                    )}

                    {isPix(processingOrder) && (
                        <Box className={classes.centeredBox}>
                            <Typography sx={{ mb: '1em' }}>
                                {i18n.t('pix_is_available')}
                            </Typography>

                            <QRCode value={order?.payment_description} />
                        </Box>
                    )}

                    {isCreditCard(processingOrder) && (
                        <>
                            <Box>
                                <CheckCircleIcon
                                    className={classes.checkCircleIcon}
                                />
                            </Box>

                            <Box className={classes.centeredBox}>
                                <Typography gutterBottom>
                                    {i18n.t('cc_has_been_charged')}
                                    <b>{order.payment_description}</b>.
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}

const getRandomMessage = () => {
    const messages = [
        'processing_payment',
        'assessing_registration_data',
        'assessing_payment_data',
        'reviewing_order',
        'reviewing_payment_data',
    ]

    const idx = Math.floor(Math.random() * messages.length)

    return messages[idx]
}

const isBoleto = (processingOrder) =>
    processingOrder?.paymentMethod ===
    CustomerPaymentInfoConstants.PAYMENT_MEHTOD_BOLETO

const isCreditCard = (processingOrder) =>
    processingOrder?.paymentMethod ===
    CustomerPaymentInfoConstants.PAYMENT_MEHTOD_CREDIT_CARD

const isPix = (processingOrder) =>
    processingOrder?.paymentMethod ===
    CustomerPaymentInfoConstants.PAYMENT_MEHTOD_PIX

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
