import PropTypes from 'prop-types'
import { useField } from 'formik'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

/** Implementation */
const displayName = 'FieldSelect'

const propTypes = {
    id: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ),
}

const defaultProps = {
    fullWidth: true,
}

const Component = ({ fullWidth, id, label, name, options, required }) => {
    const [field] = useField(name)

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel
                id={`${name}-select-label`}
                sx={{ backgroundColor: '#fff', padding: '0 6px' }}
                variant={'outlined'}
            >
                {`${i18n.t(label)}${required ? '*' : ''}`}
            </InputLabel>

            <Select labelId={`${name}-select-label`} id={id} {...field}>
                {options.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
