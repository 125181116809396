/** Commons */
import i18n from '@commons/i18n'

/** Components */
import { Box, Paper, Typography } from '@mui/material'
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined'

/** Implementation */
const displayName = 'ProductHashNotFound'

const propTypes = {}

const defaultProps = {}

const Component = () => (
    <Paper
        sx={{
            m: (theme) => theme.spacing(3),
            p: (theme) => theme.spacing(3),
        }}
    >
        <Box>
            <Box sx={{ fontSize: '12rem', height: 232 }}>
                <SentimentVeryDissatisfiedOutlinedIcon
                    sx={{
                        fontSize: 'inherit',
                        left: '50%',
                        position: 'relative',
                        transform: 'translateX(-50%)',
                    }}
                />
            </Box>

            <Box sx={{ textAlign: 'center' }}>
                <Typography variant={'h6'} gutterBottom>
                    {i18n.t('oops')}
                </Typography>
                <Typography>{i18n.t('product_hash_not_found')}</Typography>
            </Box>
        </Box>
    </Paper>
)

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default Component
