const STATUS_CANCELLED = 'CANCELLED'
const STATUS_COMPLETED = 'COMPLETED'
const STATUS_ERROR = 'ERROR'
const STATUS_RECEIVED = 'RECEIVED'

const OrderConstants = {
    STATUS_CANCELLED,
    STATUS_COMPLETED,
    STATUS_ERROR,
    STATUS_RECEIVED,
}

export default OrderConstants

export { STATUS_CANCELLED, STATUS_COMPLETED, STATUS_ERROR, STATUS_RECEIVED }
