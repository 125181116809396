import { useField } from 'formik'
import { withStyles } from '@material-ui/styles'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import { TextField, InputAdornment } from '@mui/material'

/** Implementation */
const displayName = 'FieldCurrency'

const propTypes = {}

const defaultProps = {
    currencySymbol: 'R$',
    textAlign: 'right',
}

const Component = ({
    classes,
    currencySymbol,
    disabled,
    errorText,
    InputProps,
    fullWidth,
    label,
    name,
    textAlign,
}) => {
    const [field, meta] = useField(name)

    const customInputProps = {
        ...InputProps,
        startAdornment: (
            <InputAdornment position='start'>{currencySymbol}</InputAdornment>
        ),
    }

    const inputProps = {
        className: classes.textField,
    }

    return (
        <TextField
            disabled={disabled}
            error={meta.touched && meta.error && true}
            fullWidth={fullWidth}
            InputProps={customInputProps}
            inputProps={inputProps}
            label={i18n.t(label)}
            name={name}
            type={'text'}
            variant={'outlined'}
            {...field}
        />
    )
}

const styles = (theme) => ({
    textField: (props) => ({
        textAlign: props.textAlign || 'right',
    }),
})

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default withStyles(styles)(Component)
