import { useEffect, useState } from 'react'
import { connect, useField } from 'formik'
import creditCardType from 'credit-card-type'

/** Constants */
import {
    PAYMENT_MEHTOD_BOLETO,
    PAYMENT_MEHTOD_PIX,
    PAYMENT_MEHTOD_CREDIT_CARD,
} from '@commons/constants/CustomerPaymentInfoConstants'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Typography,
    Radio,
} from '@mui/material'

import { FormCreditCardInfo } from './components'

const Component = ({ errorText, formik, fullWidth, label, name }) => {
    const [field] = useField(name)

    /** State */
    const [expanded, setExpanded] = useState(field.value)

    const [defaultMethod, setDefaultMethod] = useState(expanded);

    const [paymentOptions, setPaymentOptions] = useState([])

    /** Events */
    const handleChange = (panel) => () => {
        setExpanded(panel)

        // Initialize credit card form
        if (panel === PAYMENT_MEHTOD_CREDIT_CARD) {
            formik.setFieldValue(`credit_card_info`, {
                holder_name: '',
                digits: '',
                brand: '',
                expiration_date: '',
                security_code: '',
            })
        }
    }

    /** Effects */
    useEffect(() => {
        formik.setFieldValue(
            'credit_card_info.brand',
            getCreditCardType(formik.values.credit_card_info.digits)
        )
    }, [formik.values.credit_card_info.digits])

    useEffect(() => {
        if ((paymentOptions.includes(PAYMENT_MEHTOD_CREDIT_CARD) || paymentOptions.length === 0)) {
            setDefaultMethod(PAYMENT_MEHTOD_CREDIT_CARD)
            setExpanded(PAYMENT_MEHTOD_CREDIT_CARD)
        } else if ((paymentOptions.includes(PAYMENT_MEHTOD_BOLETO) || paymentOptions.length === 0)) {
            setDefaultMethod(PAYMENT_MEHTOD_BOLETO)
            setExpanded(PAYMENT_MEHTOD_BOLETO)
        } else {
            setDefaultMethod(PAYMENT_MEHTOD_PIX)
            setExpanded(PAYMENT_MEHTOD_PIX)
        }

    }, [paymentOptions, expanded, defaultMethod])

    useEffect(() => {
        const url = new URL(window.location.href),
            paymentOptions = url.searchParams.get('payment_options') ? url.searchParams.get('payment_options').split(',') : ''

        if (paymentOptions.length > 0 &&
            !paymentOptions.includes(PAYMENT_MEHTOD_CREDIT_CARD) &&
            !paymentOptions.includes(PAYMENT_MEHTOD_BOLETO) &&
            !paymentOptions.includes(PAYMENT_MEHTOD_PIX)) {

            setPaymentOptions([])
        } else {
            setPaymentOptions(paymentOptions)
        }
    }, [])

    /** Render */
    return (
        <FormControl fullWidth={fullWidth}>
            <FormLabel id={'demo-radio-buttons-group-label'}>
                <Typography gutterBottom>{i18n.t(label)}</Typography>
            </FormLabel>

            <RadioGroup
                aria-labelledby={'demo-radio-buttons-group-label'}
                defaultValue={defaultMethod}
                name={name}
                {...field}
            >
                {(paymentOptions.includes(PAYMENT_MEHTOD_CREDIT_CARD) || paymentOptions.length === 0) && <Accordion
                    expanded={expanded === PAYMENT_MEHTOD_CREDIT_CARD}
                    onChange={handleChange(PAYMENT_MEHTOD_CREDIT_CARD)}
                >
                    <AccordionSummary>
                        <FormControlLabel
                            control={<Radio />}
                            label={i18n.t('credit_card')}
                            value={PAYMENT_MEHTOD_CREDIT_CARD}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormCreditCardInfo />
                    </AccordionDetails>
                </Accordion>}

                {(paymentOptions.includes(PAYMENT_MEHTOD_BOLETO) || paymentOptions.length === 0) && <Accordion
                    expanded={expanded === PAYMENT_MEHTOD_BOLETO}
                    onChange={handleChange(PAYMENT_MEHTOD_BOLETO)}
                >
                    <AccordionSummary>
                        <FormControlLabel
                            control={<Radio />}
                            label={i18n.t('boleto')}
                            value={PAYMENT_MEHTOD_BOLETO}
                        />
                    </AccordionSummary>
                </Accordion>}

                {(paymentOptions.includes(PAYMENT_MEHTOD_PIX) || paymentOptions.length === 0) && <Accordion
                    expanded={expanded === PAYMENT_MEHTOD_PIX}
                    onChange={handleChange(PAYMENT_MEHTOD_PIX)}
                >
                    <AccordionSummary>
                        <FormControlLabel
                            control={<Radio />}
                            label={i18n.t('pix')}
                            value={PAYMENT_MEHTOD_PIX}
                        />
                    </AccordionSummary>
                </Accordion>}
            </RadioGroup>
        </FormControl>
    )
}

const getCreditCardType = (digits) => {
    if (digits) {
        const cards = creditCardType(digits)
        return cards.length > 0 ? cards[0]?.type : null
    }

    return null
}

export default connect(Component)
