import axios from 'axios'

const respInterceptor = (response) => {
    if (response.data.errors) {
        throw response.data.errors[0]
    }

    return response.data
}

const respErrorInterceptor = (error) => {
    if (error.response && error.response.data) {
        return Promise.reject(error.response.data.errors)
    }

    return Promise.reject([
        {
            detail: error.toString(),
            type: 'Error',
        },
    ])
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

// Adding interceptors into Axios
axiosInstance.interceptors.response.use(respInterceptor, respErrorInterceptor)

export default axiosInstance
