import PropTypes from 'prop-types'
import { connect, useField } from 'formik'

/** Commons */
import i18n from '@commons/i18n'

/** Components */
import { Autocomplete, TextField } from '@mui/material'

/** Implementation */
const displayName = 'FieldSelect'

const propTypes = {
    disablePortal: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ),
}

const defaultProps = {
    disablePortal: true,
    fullWidth: true,
    isOptionEqualToValue: (option, value) => option?.value === value?.value,
}

const Component = ({
    disabled,
    disablePortal,
    formik,
    fullWidth,
    id,
    isOptionEqualToValue,
    label,
    name,
    options,
    required,
}) => {
    const [field] = useField(name)

    return (
        <Autocomplete
            disabled={disabled}
            disablePortal={disablePortal}
            fullWidth={fullWidth}
            id={id}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={(e, option) => formik.setFieldValue(name, option)}
            options={options}
            value={field.value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={`${i18n.t(label)}${required ? '*' : ''}`}
                />
            )}
        />
    )
}

Component.displayName = displayName
Component.propTypes = propTypes
Component.defaultProps = defaultProps

export default connect(Component)
