import { Box, Link, Typography } from '@mui/material'

/** Commons */
import i18n from '@commons/i18n'

/** Implementation */
const Component = () => (
    <Box sx={{ m: 3, textAlign: 'center' }}>
        <Typography
            color={'text.primary'}
            component={'span'}
            sx={{ display: 'inline' }}
            variant={'body2'}
        >
            {i18n.t('done_with_love')}
            <Link
                href={'https://www.agilems.com/?utm_source=ams_checkout'}
                target={'_blank'}
            >
                AgileMS
            </Link>
            .
        </Typography>

        <br />

        <Typography
            color={'text.primary'}
            sx={{
                color: (theme) => theme.palette.grey[500],
                display: 'inline',
                fontSize: '0.75rem',
            }}
        >
            {process.env.REACT_APP_ENV} v3.0.5.
            {'16/05/24'.replaceAll('/', '')}
        </Typography>
    </Box>
)

export default Component
