import {
    CustomerPaymentInfoConstants,
    CustomerConstants,
} from 'commons/constants'

const contract = {
    defaultInitialValues: {
        // active_step: CheckoutConstants.STEP_REGISTER_CUSTOMER,
        active_step: null,

        // Product info
        product_name: '',
        product_hash: '',

        /*------------------------------------------------------------------
        | OrderCart
        |-----------------------------------------------------------------*/

        charges_by_uom: {},
        coupon_code: '',
        coupon: {},
        discount: {
            type: '',
            value: 0,
            result: 0
        },
        fixed_charge: 0,
        total: 0,
        subtotal: 0,
        usage_count: [],
        usage_count_subtotals: [],

        /*------------------------------------------------------------------
        | FormRegisterCustomer
        |-----------------------------------------------------------------*/

        customer_hash: '',
        customer_type: CustomerConstants.TYPE_COMPANY,

        // Customer is a person
        first_name: '',
        last_name: '',
        document: '',
        work_email: '',
        work_phone: '',

        // Customer is company
        company_cnpj: '',
        company_name: '',
        company_razao_social: '',
        company_inscricao_estadual: '',
        company_optante_simples_nacional: false,
        captcha: '',

        // Address
        postal_code: '',
        state_province: '',
        city: { label: '', value: null },
        neighbourhood: '',
        address: '',
        number: '',
        complement: '',

        /*------------------------------------------------------------------
        | FormPayment
        |-----------------------------------------------------------------*/

        amount: 0,
        payment_method: CustomerPaymentInfoConstants.PAYMENT_MEHTOD_CREDIT_CARD,
        credit_card_info: {
            digits: '',
            holder_name: '',
            brand: null,
            expiration_date: '',
            security_code: '',
        },

        /*------------------------------------------------------------------
        | Term of Use
        |-----------------------------------------------------------------*/

        term_of_use: null
    },
}

export default contract
