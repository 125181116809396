const CHECKOUT_FAILED = 5
const STEP_CART_ORDER = 0
const STEP_PAYMENT_FORM = 2
const STEP_PROCESSING_ORDER = 4
const STEP_REGISTER_CUSTOMER = 1
const STEP_REVIEW_ORDER = 3

const CheckoutConstants = {
    STEP_CART_ORDER,
    STEP_REGISTER_CUSTOMER,
    STEP_PAYMENT_FORM,
    STEP_REVIEW_ORDER,
    STEP_PROCESSING_ORDER,
    CHECKOUT_FAILED,

    INITIAL_STEP: STEP_CART_ORDER,
    LAST_STEP: STEP_REVIEW_ORDER,
}

export default CheckoutConstants
